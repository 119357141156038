import { HighBondUrlParts } from '@acl-services/highbond-common';

export interface IApis {
  authyApi: string;
  pendoMonitorScript?: string;
  accountsApi: string;
  publicApi: string;
  webComponentsUrl: string;
}

export default class PlatformApis {
  getApis(urlParts: HighBondUrlParts): IApis {
    const _isLocal: boolean = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

    const { region, domain, domainSuffix, tld } = urlParts;
    const regionSuffix = region && region != 'us' ? `-${region}` : '';
    const host = `${domain}${domainSuffix}.${tld}`;
    const protocol = _isLocal ? 'http' : 'https';

    if (_isLocal) {
      return {
        authyApi: `${protocol}://localhost:3000`,
        accountsApi: `${protocol}://localhost:3000`,
        publicApi: `${protocol}://localhost:3000`,
        webComponentsUrl: `${protocol}://localhost:3000`,
      };
    } else {
      return {
        authyApi: `${protocol}://authy-api${regionSuffix}.${host}`,
        accountsApi: `${protocol}://accounts${regionSuffix}.${host}`,
        publicApi: `${protocol}://apis${regionSuffix}.${host}`,
        pendoMonitorScript: `${protocol}://monitor${regionSuffix}.${host}/v1/monitor.js`,
        webComponentsUrl: `${protocol}://web-components${regionSuffix}.${host}`,
      };
    }
  }
}
