const defaultFlippers = {
  useNewGlobalNavBarApi: false,
  underConstructionToastEnabled: true,
  useBoardSectionTabs: false,
};

const playground: Record<string, boolean> = {
  ...defaultFlippers,
  useNewGlobalNavBarApi: true,
  underConstructionToastEnabled: false,
  useBoardSectionTabs: true,
};

const staging: Record<string, boolean> = {
  ...defaultFlippers,
  useNewGlobalNavBarApi: true,
  underConstructionToastEnabled: false,
  useBoardSectionTabs: true,
};

const preprod: Record<string, boolean> = {
  ...defaultFlippers,
  useNewGlobalNavBarApi: true,
  underConstructionToastEnabled: false,
  useBoardSectionTabs: true,
};

const production: Record<string, boolean> = {
  ...defaultFlippers,
  useNewGlobalNavBarApi: true,
  underConstructionToastEnabled: false,
  useBoardSectionTabs: true,
};

export type FlippersType = {
  staging: Record<string, boolean>;
  playground: Record<string, boolean>;
  preprod: Record<string, boolean>;
  production: Record<string, boolean>;
};

export const flippers: FlippersType = {
  playground,
  staging,
  preprod,
  production,
};
