import { HighBondUrlParts } from '@acl-services/highbond-common';

export default class Environment {
  constructor(private _urlParts: HighBondUrlParts) {}

  get isLocal(): boolean {
    return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
  }

  get isPlayground(): boolean {
    return this._urlParts.domainSuffix === '-s3' || this.isDiligentOnePlatformDev || this.isDiligentOnePlatformDevGov;
  }

  get isStaging(): boolean {
    return (
      this._urlParts.domainSuffix === '-s1' ||
      this.isDiligentOnePlatformStaging ||
      this.isDiligentOnePlatformStagingGov ||
      this.isDiligentOnePlatformStagingMil
    );
  }

  get isPreprod(): boolean {
    return this._urlParts.domainSuffix === '-s2' || this.isDiligentOnePlatformPreprod;
  }

  get isProduction(): boolean {
    return (
      (this._urlParts.domainSuffix === '' && !this.isLocal) ||
      this.isDiligentOnePlatform ||
      this.isDiligentOnePlatformGov ||
      this.isDiligentOnePlatformGov2 ||
      this.isDiligentOnePlatformGov3 ||
      this.isDiligentOnePlatformMil
    );
  }

  get isGovRegion(): boolean {
    return this.isGov || this.isSled || this.isDod;
  }

  get isGov(): boolean {
    return (
      this._urlParts.domain === 'highbond-gov' || this.isDiligentOnePlatformStagingGov || this.isDiligentOnePlatformGov
    );
  }

  get isSled(): boolean {
    return this._urlParts.domain === 'highbond-gov2' || this.isDiligentOnePlatformGov2;
  }

  get isDod(): boolean {
    return (
      this._urlParts.domain === 'highbond-mil' ||
      this._urlParts.domain + '.' + this._urlParts.tld === 'highbond.mil' ||
      this._urlParts.domain === 'highbond-gov3' ||
      this.isDiligentOnePlatformStagingMil ||
      this.isDiligentOnePlatformMil ||
      this.isDiligentOnePlatformGov3
    );
  }

  private get isDiligentOnePlatformDev(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-dev' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformStaging(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-staging' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformPreprod(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-preprod' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatform(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformDevGov(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-dev-gov' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformStagingGov(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-staging-gov' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformStagingMil(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-staging-mil' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformGov(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-gov' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformGov2(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-gov2' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformGov3(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '-gov3' &&
      this._urlParts.tld === 'com'
    );
  }

  private get isDiligentOnePlatformMil(): boolean {
    return (
      this._urlParts.domain === 'diligentoneplatform' &&
      this._urlParts.domainSuffix === '' &&
      this._urlParts.tld === 'mil'
    );
  }
}
